.main {
    position: relative;
    background-color: #f8f9fa;
  }
  
  .hero-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  
  .hero-content {
    position: relative;
    z-index: 1;
  }
  
  .cards-section {
   
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    padding: 40px;
    background: #fff;
    position: relative;
    width: 90%;
  }
  
  .stat-item {
    background-color: #fff;
    padding: 20px;
 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    
  }
  
  @media (max-width: 768px) {
    .hero-image {
        height: auto;
    }
  
    .cards-section {
        width: 100%;
    }
  
    .stat-item {
        margin: 0 auto;
    }
  }
  

  .admissions-container {
    font-family: Arial, sans-serif;
    margin-bottom: 0px;
  }
  
  .hero-section .hero-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .services-section .service-card img {
    width: 50px;
    height: 50px;
  }
  
  .services-section .service-card {
    display: flex;
    flex-direction: column;
    
  }
  
  .section-container {
    display: flex;
    flex-direction: row;
   
    margin-top: 2rem;
    margin-bottom: 1rem;
    
    padding: 2rem;
   
  }
  
  .text-section1 {
    flex: 1;
   margin-left:9%;
   margin-top: 5%;
  }
  .text-section2 {
    flex: 1;
   margin-left:-5%;
   margin-top: 5%;
  }
  .section-title {
   margin-left: 2%;
    font-weight: bold;
    color: #F97316;
  }
  .section-title-left {
    margin-left: 1.5%;
     font-weight: bold;
     color: #F97316;
   }
  .section-description {
    margin-top: 1rem;
    margin-left: 50px;
    text-align: left;
  }
  
  .image-collage {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .image-collage .image-bg {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 0.5rem;
  }
  
  .image-collage .image-bg img {
    width: 100%;
    height: auto;
  }
  
  .custom-button {
    background-color: #F97316;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.375rem; /* rounded-md */
    cursor: pointer;
    margin-top: 1rem;
    text-align: left;
    margin-left: -42%;
  }
  .custom-buttons-left {
    background-color: #F97316;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.375rem; /* rounded-md */
    cursor: pointer;
    margin-top: 1rem;
    text-align: left;
    margin-left: -73%;
  }
  .custom-button:hover {
    background-color: #D65A12;
  }
  

  .custom-container {
   
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    
  }
  
  .custom-button {
    background-color: #e95420;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .custom-button:hover {
    background-color: #d84315;
  }
  

  .black-background {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: #111827;
    z-index: 1;
  }
  
  .video-card {
    position: relative;
    width: 300px; /* Adjust width as needed */
  }
  
  .video-wrapper {
    position: relative;
  }
  
  .video-frame {
    width: 100%;
    height: 200px; /* Adjust height as needed */
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  
  
  
  .pagination-dot {
    width: 10px;
    height: 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    margin: 0 5px;
  }
  
  .pagination-dot.active {
    background-color: #111827;
  }
  


  .talk-to-counsellor{
    margin-left: 65px;
    width: 90%;
  }

  ul.list-disc {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  li {
    margin-bottom: 10px;
  }
  
  .aligned-list {
    display: flex;
    flex-direction: column;
    
  }
  
  ul.aligned-list {
    list-style-type: none;
    padding-left: 0;
  }
  
  ul.aligned-list li::before {
    content: "\2022";
    color: #ff6633; /* Custom bullet color */
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
  
  ul.aligned-list li {
    margin-left: 20px; /* Aligns the text with the custom bullet */
  }
  .custom-buttons{
    margin-left: -79%;
}

.top-buttons{
   margin-left: -72%;
}

/* Add this to your existing CSS file */
.stat-item  {
    transition: transform 0.3s ease; /* Smooth transition */
}

.stat-item:hover  {
    transform: scale(1.2); /* Zoom effect on hover */
}
.black-background {
    background-color: #333333;
    height: 60%; /* Adjust the height as needed */
    width: 100%; /* Adjust the width as needed */
    position: absolute; /* Ensure it is positioned correctly */
    top: 40%; /* Adjust based on where you want it to appear */
    left: 0;
}



.section-description{
    margin-left: -2px;
}



.bg-gray-400{
  background: #eeeeee;
}

.bg-orange{
  color: #ff6633;
}

.main-icons .link-item {
  transition: transform 0.3s ease; /* Smooth transition */
}

.main-icons .link-item:hover {
  transform: scale(1.2); /* Scale up on hover */
}
