@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

* {
  font-family: 'Poppins', sans-serif;
}

@keyframes moveLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.university-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  animation: moveLeft 20s linear infinite;
}

.university-item {
  display: inline-block;
  width: calc(100% / 3); /* Show three icons in a row */
  padding: 0 5px; /* Add gap between icons */
  box-sizing: border-box;
}

.university-logo {
  max-width: 100%;
  height: auto;
}

.university-text {
  margin-top: 10px;
  text-align: center;
}

/* Media Queries for Responsiveness */
@media (min-width: 640px) {
  .university-item {
    width: calc(100% / 3); /* Show three icons in a row for small screens */
  }
}

@media (min-width: 768px) {
  .university-item {
    width: calc(100% / 4); /* Show four icons in a row for medium screens */
  }
}

@media (min-width: 1024px) {
  .university-item {
    width: calc(100% / 5); /* Show five icons in a row for large screens */
  }
}
