@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

* {
  font-family: 'Poppins', sans-serif;
}
.text-gradient {
    background: linear-gradient(90deg, #f43f5e, #f59e0b, #f43f5e, #f59e0b);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .bg-black {
    background-color: black;
  }
  
  .text-white {
    color: white;
  }
  
  .bg-orange-500 {
    background-color: #f97316;
  }
  
  .min-h-screen {
    min-height: 100vh;
  }
  
  .p-2 {
    padding: 0.5rem;
  }
  
  .mb-4 {
    margin-bottom: 1rem;
  }
  
  .mb-6 {
    margin-bottom: 1.5rem;
  }
  
  .mt-4 {
    margin-top: 1rem;
  }
  
  .rounded-md {
    border-radius: 0.375rem;
  }
  
  .text-center {
    text-align: center;
  }
  
  .font-bold {
    font-weight: 700;
  }
  
  .text-7xl {
    font-size: 5rem;
   
  }
  
  .flex {
    display: flex;
  }
  
  .items-center {
    align-items: center;
  }
  
  .justify-center {
    justify-content: center;
  }
  
  .max-w-lg {
    max-width: 32rem;
  }
  