@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

* {
  font-family: 'Poppins', sans-serif;
}

/* Styling for the contact cards */
.contact-card {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Increased shadow for better effect */
  margin-top: -60px; /* Increase overlap with the black strip */
  width: 90%; /* Responsive sizing */
  max-width: 220px; /* Slightly larger to better match the image */
  border-top: 4px solid #FF6630; /* Orange top border */
}

/* Font styling */


/* Icon color adjustment */
.contact-card img, .location-icon {
  filter: invert(50%) sepia(78%) saturate(4988%) hue-rotate(348deg) brightness(96%) contrast(105%); /* Adjust icon color to orange */
}

/* Button styling */
.custom-send-button {
  font-size: 1rem; /* Larger font for better readability */
  padding: 0.75rem 1.5rem; /* Adjusted padding for a larger button */
  background-color: #FF6630; /* Match the button color to the image */
  color: white;
  border: none;
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow for button */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-send-button:hover {
  background-color: #e66e00; /* Slightly darker on hover */
}

/* Input and textarea styling */
input, textarea {
  border: 1px solid #ccc;
  padding: 0.75rem;
  border-radius: 0.25rem;
  font-size: 1rem;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .contact-card {
    width: 100%; /* Full width on mobile */
    margin-top: 0px; /* Remove negative margin on mobile */
    margin-bottom: 20px; /* Add spacing between cards */
  }
  .flex-col {
    flex-direction: column;
  }
  .grid-cols-2 {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .p-6 {
    padding: 1rem;
  }
  .text-2xl {
    font-size: 1.5rem;
  }
  .text-lg {
    font-size: 1.25rem;
  }
  .custom-send-button {
    width: 100%;
    padding: 1rem;
  }
  .contact-card {
    margin: 0 auto 20px auto;
  }
  .text-center {
    text-align: center;
  }
}

.bg-333333 {
  background-color: #333333;
}
