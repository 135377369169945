/* BlogPage.css */
.error-message {
    color: red;
    text-align: center;
    margin: 20px 0;
  }
  
  /* Custom styling for the related blogs */
  .overflow-x-scroll {
    display: flex;
    overflow-x: auto;
  }
  
  .w-80 {
    min-width: 320px; /* Ensure consistent width for cards */
  }
  
  .bg-gray-900 {
    background-color: #1a202c;
  }
  
  .bg-gray-200 {
    background-color: #edf2f7;
  }
  
  .bg-orange-500:hover {
    background-color: #ea580c;
  }
  
  /* Additional custom styles */
  .bg-white {
    background-color: #ffffff;
  }
  
  .text-center {
    text-align: center;
  }
  
  .font-semibold {
    font-weight: 600;
  }
  
  .text-white {
    color: #ffffff;
  }
  
  .rounded-md {
    border-radius: 0.375rem;
  }
  
  .px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  .py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  
  .shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
  }
  
  .hover\:bg-orange-600:hover {
    background-color: #dd6b20;
  }
  
  .mb-4 {
    margin-bottom: 1rem;
  }
  
  .mt-8 {
    margin-top: 2rem;
  }
  
  .mb-4 {
    margin-bottom: 1rem;
  }
  
  .mt-8 {
    margin-top: 2rem;
  }
  
  .flex {
    display: flex;
  }
  
  .justify-center {
    justify-content: center;
  }
  
  .gap-4 {
    gap: 1rem;
  }
  
  .overflow-hidden {
    overflow: hidden;
  }
  
  .cursor-pointer {
    cursor: pointer;
  }
  .blog-content p {
    margin-bottom: 1em;
    line-height: 1.6;
  }
  
  .blog-content p.indent {
    text-indent: 2em;
  }
  
  .blog-content h2,
  .blog-content h3 {
    font-weight: bold;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    color: #333;
  }

  .blog-page {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .blog-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .blog-content {
    max-width: 800px;
    margin-bottom: 40px;
  }
  
  .blog-content h2,
  .blog-content h3 {
    font-weight: bold;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    color: #333;
  }
  
  .blog-content p,
  .blog-content ul {
    margin-bottom: 1em;
    line-height: 1.6;
  }
  
  .blog-content ul {
    padding-left: 1.5em;
  }
  
  .form-and-blogs {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 800px;
  }
  
  .form-section,
  .related-blogs {
    width: 45%;
  }
  
  .form-section form {
    display: flex;
    flex-direction: column;
  }
  
  .form-section label {
    margin-bottom: 0.5em;
    font-weight: bold;
  }
  
  .form-section input,
  .form-section select,
  .form-section button {
    margin-bottom: 1em;
    padding: 0.5em;
    font-size: 1em;
  }
  
  .form-section button {
    cursor: pointer;
    background-color: #4CAF50;
    color: white;
    border: none;
  }
  
  .related-blogs ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .related-blogs li {
    cursor: pointer;
    margin-bottom: 0.5em;
    color: #007BFF;
  }
  
  .related-blogs li:hover {
    text-decoration: underline;
  }
  
  .pagination {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 800px;
    margin-top: 20px;
  }
  
  .pagination button {
    padding: 0.5em 1em;
    font-size: 1em;
    cursor: pointer;
  }
  
  .pagination button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  
  