.profile-list {
  display: flex;
  flex-direction: column;
}

ul.profile-list {
  list-style-type: none;
  padding-left: 0;
}

ul.profile-list li::before {
  content: "\2022";
  color: #000000; /* Custom bullet color */
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

ul.profile-list li {
  margin-left: 20px; /* Aligns the text with the custom bullet */
}


.main-form{
  display: flex;
  align-items: center;
  justify-content: center ;
}

  
  
  .form-container {
    width: 40%;
    height: 300px;
  }
  
  .next-button {
    color: #FF5722;
  }
  

  .label-1{
    margin-right: 500px;
    
  }
.backgrounds{
  width: 100vw;
  margin-left: -35px;
}
  
.quesnLabel{
  width: 1200px;
  display: flex;
  flex-direction: row;
}
  .normal-text {
    word-spacing: normal;
    letter-spacing: normal;
  }
  
  .leap-section{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin-left: 10%;
  }

#subheading{
  margin-left: 22rem;
}

.section-imagesleft{
  width: 50%;
}
.profile-image{
  width: 54%;

}
.section-textright{
  width: 50%;
  margin-left: 50%;
  margin-top: -37%;
  font-size: 20px;
  height: 500px;
  font-weight: 400;
}

.images{
  height: 500px;
  margin-top: -45px;
}


.arrows {
  background-color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: #F54C1E;
  border: 1px solid #F54C1E;
}