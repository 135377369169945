/* EssayReview.css */

/* Adjust the positioning of top buttons */
.custom-buttons {
    margin-left: -54%; /* Adjust based on your layout */
}

.top-buttons {
    margin-left: -50%; /* Adjust based on your layout */
}

/* Ensure smooth zoom effect on hover for stat items */
.stat-item {
    transition: transform 0.3s ease;
    padding: 0px;
}

.stat-item:hover {
    transform: scale(1.2);
}

/* Ensure cards maintain size and layout consistency */
.section-container {
    margin: 1rem 0; /* Adjust margin for spacing */
}

.section-title {
    margin-bottom: 0.5rem; /* Adjust spacing for section titles */
}

.section-description {
    padding-left: 1rem; /* Adjust padding for text alignment */
}

.section-description li {
    margin-bottom: 0.3rem; /* Adjust space between list items */
}

.images {
    max-width: 100%;
    height: auto;
    margin-bottom: 0.5rem; /* Adjust margin for images */
}

/* Ensure FAQ section maintains spacing */
.faq-container {
    margin-top: 1rem; /* Adjust top margin as needed */
}


.main-icons{
    margin-left: 10%;
    width: 80%;
}