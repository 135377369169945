@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

* {
  font-family: 'Poppins', sans-serif;
}



.top-section {
  height: 400px;
  width: 100%;
  
}



.arrow-btns {
  margin-left: 95%;
}

.video-container {
  display: flex;
  justify-content: center;
  align-items: center;

}

.youtube-video {
  width: 80%;
  height: 600px;
  margin-left: 34%;
}

.university-container {
  display: flex;
  justify-content: center;
}

.university-slider {
  display: flex;
  justify-content: space-between;
}

.university-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.university-logo {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.university-text {
  margin-top: 0.5rem;
}
.bg-grey{
  background-color: #333333;
}
.leading-snug1{
  margin-left: -30%;
}
.leading-snug2{
  margin-left: -27%;
}
.ready-section {
  background-image: url('../../assets/Homepage/AA_Home_ReadyToTakeTheLeap.png');

  text-align: center;
}
.orange-line{
  margin-bottom: -8px;
  border-bottom: 2px solid #ff6633;
}

.ready-section h2 {
  margin-bottom: 1rem;
}

.ready-section p {
  margin-bottom: 1rem;
}

.ready-section button {
  background-color: white;
  color: black;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-size: 1.25rem;
  font-weight: bold;
  cursor: pointer;
}

@media (max-width: 768px) {
  .youtube-video {
    height: 250px;
  }
}

.text-333333 {
  color: #333333;
}


.card-heading1{
  margin-left: -22%;
}

.card-heading2{
  margin-left: -7%;
}

