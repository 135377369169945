@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

* {
  font-family: 'Poppins', sans-serif;
}
.dropdown-menu {
  display: none;
}

.relative:hover .dropdown-menu {
  display: block;
}

@media (min-width: 1024px) {
  .logo {
    margin-left: -80%;
  }
  .lg\:hidden {
    display: none !important;
  }
  .lg\:block {
    display: block !important;
  }
  .lg\:inline-block {
    display: inline-block !important;
  }
  .lg\:flex {
    display: flex !important;
  }
  .lg\:w-auto {
    width: auto !important;
  }
  .lg\:w-full {
    width: 100% !important;
  }
  .lg\:items-center {
    align-items: center !important;
  }
  .lg\:ml-auto {
    margin-left: auto !important;
  }
  .lg\:mt-0 {
    margin-top: 0 !important;
  }
  .lg\:space-x-4 > * + * {
    margin-left: 1rem;
  }
  .lg\:space-x-6 > * + * {
    margin-left: 1.5rem;
  }
  .lg\:mb-0 {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 1023px) {
  .w-full {
    width: 100%;
  }
  .block {
    display: block !important;
  }
  .hidden {
    display: none !important;
  }
  .flex-1 {
    flex: 1;
  }
  .dropdown-menu {
    position: static;
    display: block;
    border: none;
    box-shadow: none;
    background: transparent;
    padding: 0;
    margin: 0;
    width: 100%;
  }
  .dropdown-menu .block {
    padding: 10px;
    background: #ffffff;
    border-bottom: 1px solid #e5e7eb;
  }
}

nav {
  border-bottom: 1px solid #e5e7eb; /* Light grey border at the bottom */
  z-index: 10;
}

.relative:hover .dropdown-menu,
.relative:focus-within .dropdown-menu {
  display: block;
}

.dropdown-menu {
  z-index: 1000; /* High z-index for dropdowns */
}
