@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

* {
  font-family: 'Poppins', sans-serif;
}

.main {
  position: relative;
  background-color: #f8f9fa;
}

@media(min-width: 1024px) {
  #hero-text {
    margin-left: -36%;
  }
  .cards-section {
    margin-top: -72px;
  }
  #hero-button {
    margin-left: -82%;
  }
  #hero-subtext {
    margin-left: -51%;
  }
}

.hero-image {
  width: 100%;
  height: 100vh; /* Increased height */
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.hero-content {
  position: relative;
  z-index: 1;
  text-align: center;
}

.cards-section {
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 40px;
  background: #fff;
  align-items: center;
  margin-left: 5%;
  width: 90%; /* Default width */
}

.stat-item {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px; /* Ensuring uniform height */
}

.stat-item .text-orange-500 {
  font-size: 3rem; /* Adjusting icon size for uniformity */
}

.stat-item p {
  margin: 0;
}

.stat-item .value {
  font-size: 1.5rem; /* Adjusting text size */
  color: #333;
}

.stat-item .label {
  font-size: 1rem;
  color: #666;
}

.pattern-dots {
  position: absolute;
  width: 40px;
  height: 40px;
  background-image: radial-gradient(circle, #000 1px, transparent 1px);
  background-size: 5px 5px;
  z-index: 3;
}

.pattern-dots.top-right {
  top: -20px;
  right: -20px;
}

.pattern-dots.bottom-left {
  bottom: -20px;
  left: -20px;
}

@media (max-width: 768px) {
  .hero-image {
    width: 100vw;
    height: 70vh; /* Increased height for smaller screens */
  }

  .cards-section {
    width: 70%;
    padding: 20px;
    margin-top: -106px;
  }

  .pattern-dots {
    display: none;
    height: 0px;
  }

  .stat-item {
    margin: 0 auto;
    width: 100%;
    margin-bottom: 20px;
  }

  .hero-content {
    padding: 20px;
  }

  #hero-text {
    font-size: 2rem;
    margin-top: 20px;
  }

  #hero-button {
    margin-top: 20px;
    font-size: 1rem;
  }

  #hero-subtext {
    font-size: 1rem;
    margin-top: 10px;
  }

  .stat-item .text-orange-500 {
    font-size: 2rem; 
  }

  .stat-item .value {
    font-size: 1.25rem;
  }

  .stat-item .label {
    font-size: 0.875rem;
  }
}

@media (max-width: 480px) {
  .hero-image {
    width: 100vw;
    height: 80vh; /* Increased height for the smallest screens */
  }

  .hero-content {
    padding: 10px;
  }

  #hero-text {
    font-size: 1.5rem;
  }

  #hero-button {
    margin-top: 10px;
    font-size: 0.875rem;
  }

  .cards-section {
    margin-top: -86px;
  }

  #hero-subtext {
    font-size: 0.875rem;
    margin-top: 5px;
  }

  .cards-section {
    width: 95%;
    margin: 0 auto;
    padding: 10px;
  }

  .stat-item {
    height: auto;
    margin-bottom: 10px;
  }

  .stat-item .text-orange-500 {
    font-size: 1.5rem; 
  }

  .stat-item .value {
    font-size: 1rem;
  }

  .stat-item .label {
    font-size: 0.75rem;
  }
}
