@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

* {
  font-family: 'Poppins', sans-serif;
}

.main {
  position: relative;
  background-color: #f8f9fa;
}

.abroad-maintext, .abroad-subtext, .abroad-button {
  margin: 0;
}

.arrows {
  background-color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: #F54C1E;
  border: 1px solid #F54C1E;
}

.arrows:hover {
  background-color: #f0f0f0;
}

.text-width1 {
  width: 63%;
}

.text-width2 {
  width: 74%;
}

.test-head {
  font-size: 40px;
}

.testimonial {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 35%;
  margin-top: 50px;
  width: 42%;
}

.testimonial-image {
  margin-left: -20%;
}

.custom-shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.half-black-container {
  background: linear-gradient(to bottom, white 0%, white 50%, #333232 50%, #333232 100%);
}

.community-container {
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
}

.community-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  justify-items: center;
}

.community-card {
  padding: 1rem;
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 260px;
  margin-bottom: 1rem;
}

.community-icon {
  margin-right: 1rem;
}

.community-text {
  font-size: 18px;
  font-weight: 550;
}

.bg-light-green {
  background-color: #f8f9f4;
}

.bg-light-red {
  background-color: #fff5f5;
}

.bg-light-purple {
  background-color: #f6f3f9;
}

.bg-light-orange {
  background-color: #fef7f3;
}

.p-8 {
  padding: 2rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.text-center {
  text-align: center;
}

.mb-8 {
  margin-bottom: 2rem;
}

.text-2xl {
  font-size: 1.5rem;
}

.font-bold {
  font-weight: 700;
}

.text-orange-500 {
  color: #ff6633;
}

.grid {
  display: grid;
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.gap-4 {
  gap: 1rem;
}
@media (min-width: 1024px) {
.abroad-maintext{
  margin-left: -42%;
}
.abroad-subtext{
  margin-left: -45%;
}
.abroad-button{
  margin-left: -84%;
}
}
@media (max-width: 768px) {
  .testimonial {
    margin-top: 180px;
    flex-direction: column;
  }

  .testimonial-image {
    margin-left: 0;
    width: 100%;
    height: auto;
  }

  .testimonial > div {
    text-align: center;
    width: 100%;
    text-align: left;
    margin-left: 0;
  }

  .testimonial div.bg-white {
    margin-left: 0;
    width: 100%;
    padding: 1rem;
  }

  .test-head {
    font-size: 30px;
    text-align: center;
  }

  .arrows {
    visibility: hidden;
  }

  .w-full {
    width: 100%;
  }

  .community-grid {
    grid-template-columns: 1fr;
    width: 100%;
  }

  .community-card {
    width: 100%;
    margin-left: 0;
    height: auto;
  }

  .community-text {
    font-size: 14px;
  }

  .rounded-lg {
    border-radius: 0.25rem;
  }

  .mb-8 {
    margin-bottom: 1rem;
  }

  .text-2xl {
    font-size: 1.25rem;
  }

  .abroad-maintext,
  .abroad-subtext,
  .abroad-button {
    margin-left: 0;
    text-align: center;
    width: 100%;
  }

  .abroad-subtext {
    font-size: 1rem;
    margin-top: 1rem;
  }

  .abroad-button {
    font-size: 1rem;
  }
}
