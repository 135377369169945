@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

* {
  font-family: 'Poppins', sans-serif;
}

.text-center {
  text-align: center;
}

.text-2xl {
  font-size: 1.5rem;
}

.font-bold {
  font-weight: 700;
}

.mb-8 {
  margin-bottom: 2rem;
}

.p-8 {
  padding: 2rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.border-b {
  border-bottom: 1px solid #e5e7eb; /* Tailwind gray-300 */
}

.border-orange-500 {
  border-color: #ff6633; /* Tailwind orange-500 */
}

.bg-white {
  background-color: #ffffff;
}

.text-gray-800 {
  color: #1f2937; /* Tailwind gray-800 */
}

.flex {
  display: flex;
  text-align: justify; /* Align text starting in a straight line */
}

.items-center {
  align-items: center;
}

.justify-around {
  justify-content: space-around;
}

.mr-8 {
  margin-right: 2rem;
}

.h-12 {
  height: 3rem;
}

.w-12 {
  width: 3rem;
}

.h-72 {
  height: 20rem; /* Increased size of the image container */
}

.w-72 {
  width: 20rem; /* Increased size of the image container */
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.rounded-md {
  border-radius: 0.375rem;
}

.shadow-md {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.border-gray-800 {
  border-color: #333333; /* Tailwind gray-800 */
}

.bg-transparent {
  background-color: transparent;
}

.ml-2 {
  margin-left: 0.5rem;
}

ul.list-disc {
  list-style-type: disc;
  margin-left: 20px;
}

li {
  margin-bottom: 10px;
}

.aligned-list {
  display: flex;
  flex-direction: column;
}

ul.aligned-list {
  list-style-type: none;
  padding-left: 0;
}

ul.aligned-list li::before {
  content: "\2022";
  color: #ff6633; /* Custom bullet color */
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

ul.aligned-list li {
  margin-left: 20px; /* Aligns the text with the custom bullet */
}

@media (max-width: 768px) {
  .work-text {
    font-size: 1.5rem;
    visibility: visible;
  }

  .icons {
    flex-wrap: wrap;
    justify-content: center;
    font-size: 10px;
    margin-top: -10%;
  }

  .section-image {
    width: 100%;
    height: auto;
    margin: 0 auto;
    border-radius: 2px;
    display: block;
  }

  .section-content {
    margin-top: 12px;
    padding: 0 1rem;
  }

  .image-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
